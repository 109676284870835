import React from "react";
import  './index.css';

const Section = ({ section, renderedInUL }) => {
  const renderContent = (section) => {
    switch (section.type) {
      case "heading":
        switch (section.level) {
          case 1:
            return <h1>{section.text_content}</h1>;
          case 2:
            return <h2>{section.text_content}</h2>;
          case 3:
            return <h3>{section.text_content}</h3>;
          case 4:
            return <h4>{section.text_content}</h4>;
          case 5:
            return <h5>{section.text_content}</h5>;
          default:
            return <h6>{section.text_content}</h6>;
        }
      case "para":
        return <p>{section.text_content}</p>;
      case "note":
        return <blockquote>{section.text_content}</blockquote>;
      case "list":
        return (
          <ul>
            {section.sub_section.map((subSec, idx) => (
              <Section key={idx} renderedInUL={true} section={subSec} />
            ))}
          </ul>
        );
      case "list_item":
        if (renderedInUL) {
          return <li>{section.text_content}</li>;
        }
        break;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderContent(section)}
      {section.sub_section &&
        section.sub_section.map((subSec, idx) => (
          <Section key={idx} section={subSec} />
        ))}
    </div>
  );
};
// Main InfoPage component
const InfoPage = ({ data }) => {
  return (
        <Section section={data}  />
  );
};

export default InfoPage;