import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const ProtectedRoute = ({ element: Component, app, ...rest }) => {
  const location = useLocation();
  const token = Cookies.get('jwt_token');

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const parsedToken = parseJwt(token);
  const availableApps = parsedToken?.accessible_apps.map(app => app.toLowerCase()) || [];

  if (!availableApps.includes(app.toLowerCase())) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
