// /src/components/common/PdfDetails.js
import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  forwardRef,
} from "react";
import PatientContext from "../../../context/PatientContext";
import "./index.css";
import ReactToPrint from "react-to-print";
import ApolloLogo from "../../common/img/Apollo_logo.svg";
import Cookies from "js-cookie";
import Apollo24 from "../../common/img/Apollo-247-Onl-1.png";
import ApolloImg from "../../common/img/download.png";
import { useNavigate } from "react-router-dom";

const PrintPdfDetails = forwardRef((props, ref) => {
  const [patientInfo, setPatientInfo] = useState({});
  const [attributeInfo, setAttributeInfo] = useState(props.PdfInfo);
  const { patientDetails } = useContext(PatientContext);
  const { contextPatientReport } = useContext(PatientContext);
  const [apidata, setAPI] = useState();

  useEffect(() => {
    setPatientInfo(patientDetails);
    setAttributeInfo(props.PdfInfo);
    const report = contextPatientReport.Data[0];
    const prediction = report.Prediction;
    let topRiskContributors = prediction.HeartRisk.TopRiskContributors;
    setAPI({
      optimal: prediction.HeartRisk.Acceptable,
      risk: prediction.HeartRisk.Risk,
      score: prediction.HeartRisk.Score,
      topRiskContributors: topRiskContributors,
      department: prediction.MedicalProtocol.Referral.Department,
      urgency: prediction.MedicalProtocol.Referral.Urgency,
      advice: prediction.MedicalProtocol.Management.GeneralAdvice,
      medication: prediction.MedicalProtocol.Medication,
      diagnosticsAndImagingRecommended:
        prediction.MedicalProtocol.DiagnosticsAndImagingRecommended,
      labInvestigationRecommended:
        prediction.MedicalProtocol.LabInvestigationRecommended,
      repeatVisitComment: prediction.ManagementRepeatVisit,
      repeatVisitDuration:
        prediction.MedicalProtocol.Management.RepeatVisit.Duration,
      repeatVisitUnits: prediction.MedicalProtocol.Management.RepeatVisit.Unit,
      loaderStatus: false,
      aicvd_note: report.aicvd_note,
      treatment_goals: report.treatment_goals,
      AICVD_Cardiac_Risk_Score: report.AICVD_Cardiac_Risk_Score,
      note: report.note,
      riskProfile: report.risk_profile,
    });
  }, [patientDetails, props.PdfInfo, contextPatientReport]);

  const renderCurrentDate = () => {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  let riskCardColor = "";

  if (apidata && apidata.risk === "High Risk")
    riskCardColor = "risk-card-bg-red";
  else if (apidata && apidata.risk === "Moderate Risk")
    riskCardColor = "risk-card-bg-yellow";
  else riskCardColor = "risk-card-bg-green";

  // const loginDetails=Cookies.get('login_details')
  const loginDetails = JSON.parse(Cookies.get("login_details"));

  return (
    <div className="pdfreportform" ref={ref}>
      <div className="pdfpage">
        <div className="pdf-header-conatiner">
          <img
            className="pdf-apollo-logo"
            src={ApolloLogo}
            alt="Apollo_logo.png"
          />
          <h1 className="RiskScoreHeading">AICVD Risk Score Report</h1>
          <p></p>
        </div>
        <div className="pdf-report-patient-detials-container">
          <ul className="user-details">
            {Object.entries(patientInfo).map(
              ([key, value]) =>
                key !== "email" && (
                  <li key={key}>
                    <p className="pateint-info-heading">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    :<p className="patient-info-value">{value}</p>
                  </li>
                )
            )}
          </ul>
          <div className="info-container">
            <p className="info-title width-65">Date of Report</p>
            <p className="info-colon">:</p>
            <p className="info-value">{renderCurrentDate()}</p>
          </div>
        </div>
        <div className="attributes-info-container">
          <p className="box-heading attribute-info-bg">Atrributes</p>
          <ul className="pdf-attributes-fields-container">
            {Object.entries(attributeInfo).map(
              ([key, value]) =>
                value !== "" && (
                  <li key={key} className="field-container">
                    <p className="field-text">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    <p className="field-date-container attribute-border">
                      {value}
                    </p>
                  </li>
                )
            )}
          </ul>
        </div>
        <div>
          <div className="pdf-report-heading-conatiner">
            <div className="left-line"></div>
            <h1 className="report-sub-heading">PATIENT CARDIAC RISK SCORE</h1>
            <div className="right-line"></div>
          </div>
          {apidata && (
            <>
              <div className="risk-box-container">
                <div className="each-risk-box">
                  <p className="score-box-heading">Risk Status</p>
                  <div className={` ${riskCardColor} score-box`}>
                    {apidata.risk}
                  </div>
                </div>
                <div className="each-risk-box">
                  <p className="score-box-heading">Patient Score</p>
                  <div className="score-box font-color">{apidata.score}</div>
                </div>
                <div className="each-risk-box">
                  <p className="score-box-heading">Acceptable Score</p>
                  <div className="score-box font-color">{apidata.optimal}</div>
                </div>
              </div>
              <p className="font-size">{apidata.note}</p>
              <p className="font-size">
                Note: <span className="">{apidata.aicvd_note}</span>
              </p>
            </>
          )}
        </div>
        <footer className="footer-container">
          <p className="footer-text mt-auto">
            This report is accessed by {loginDetails.executive}
          </p>
          <div className="date-pageNo-container">
            <div className="date-time-container">
              <p className="date-text">Date: {renderCurrentDate()}</p>
            </div>
            <p className="page-no-text">Page 1/3</p>
          </div>
        </footer>
      </div>
      <div className="pdfpage">
        <div className="pdf-report-patient-detials-container">
          <ul className="user-details">
            {Object.entries(patientInfo).map(
              ([key, value]) =>
                key !== "email" && (
                  <li key={key}>
                    <p className="pateint-info-heading">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    :<p className="patient-info-value">{value}</p>
                  </li>
                )
            )}
          </ul>
          <div className="info-container">
            <p className="info-title width-65">Date of Report</p>
            <p className="info-colon">:</p>
            <p className="info-value">{renderCurrentDate()}</p>
          </div>
        </div>
        {apidata && (
          <>
            <div className="pdf-report-form">
              <div className="">
                <div>
                  <div className="pdf-report-heading-conatiner">
                    <div className="left-line"></div>
                    <h1 className="report-sub-heading">
                      TOP MODIFIABLE RISK FACTORS
                    </h1>
                    <div className="right-line"></div>
                  </div>

                  <ul className="risk-box-container">
                    {apidata.topRiskContributors !== null ? (
                      apidata.topRiskContributors?.map((each) => (
                        <li className="risk-factors font-color" key={each}>
                          {each === "bmi" ? "BMI" : each}
                        </li>
                      ))
                    ) : (
                      <li className="top-risk-card">None</li>
                    )}
                  </ul>
                  <p className="font-size">
                    {apidata.AICVD_Cardiac_Risk_Score &&
                      apidata.AICVD_Cardiac_Risk_Score["description"]}
                  </p>
                  <p className="report-recommended-list-item-heading">
                    DISCLAIMER
                  </p>
                  <ul className="font-size">
                    {apidata.AICVD_Cardiac_Risk_Score &&
                      apidata.AICVD_Cardiac_Risk_Score["disclaimer"].map(
                        (line, index) => (
                          <li className="" key={index}>
                            {line}
                          </li>
                        )
                      )}
                  </ul>
                  <div className="pdf-report-heading-conatiner">
                    <div className="left-line"></div>
                    <h1 className="report-sub-heading">RECOMMENDED PROTOCOL</h1>
                    <div className="right-line"></div>
                  </div>
                  <p className="report-recommended-list-item-heading">
                    LAB INVESTIGATION
                  </p>
                  <ul className="report-recommended-list-item-value">
                    {apidata.labInvestigationRecommended &&
                      Object.entries(apidata.labInvestigationRecommended)
                        .filter(([key, value]) => value === "Yes")
                        .map(([key]) => key)
                        .join(", ")}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
        <footer className="footer-container">
          <p className="footer-text mt-auto">
            This report is accessed by {loginDetails.executive}
          </p>
          <div className="date-pageNo-container">
            <div className="date-time-container">
              <p className="date-text">Date: {renderCurrentDate()}</p>
            </div>
            <p className="page-no-text">Page 2/3</p>
          </div>
        </footer>
      </div>
      <div className="pdfpage">
        <div className="pdf-report-patient-detials-container">
          <ul className="user-details">
            {Object.entries(patientInfo).map(
              ([key, value]) =>
                key !== "email" && (
                  <li key={key}>
                    <p className="pateint-info-heading">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                    :<p className="patient-info-value">{value}</p>
                  </li>
                )
            )}
          </ul>
          <div className="info-container">
            <p className="info-title width-65">Date of Report</p>
            <p className="info-colon">:</p>
            <p className="info-value">{renderCurrentDate()}</p>
          </div>
        </div>
        {apidata && (
          <>
            <div className="pdf-report-form">
              <div className="">
                <p className="report-recommended-list-item-heading">
                  DIAGNOSTICS AND IMAGING
                </p>
                <ul className="report-recommended-list-item-value">
                  {apidata.diagnosticsAndImagingRecommended &&
                    Object.entries(apidata.diagnosticsAndImagingRecommended)
                      .filter(([key, value]) => value === "Yes")
                      .map(([key]) => key)
                      .join(", ")}
                </ul>
                <p className="report-recommended-list-item-heading">
                  MEDICATION
                </p>
                <ul className="report-recommended-list-item-value">
                  {apidata.medication &&
                    Object.entries(apidata.medication).map(([key, value]) => {
                      if (value !== "") {
                        return <li key={key}>{key}</li>;
                      } else {
                        return null; // If value is not 'Yes', don't render anything
                      }
                    })}
                </ul>
                <p className="report-recommended-list-item-heading">REFERRAL</p>
                <p className="report-recommended-list-item-value">
                  {apidata.department} Referral ({apidata.urgency})
                </p>
                <p className="report-recommended-list-item-heading">
                  TREATMENT GOALS
                </p>
                <ul className="report-recommended-list-item-value">
                  {apidata.treatment_goals.map((field) => (
                    <li className="report-recommended-list-item-value">
                      <span className="">{field.heading}</span>
                      {field.description}
                    </li>
                  ))}
                </ul>
                <p className="report-recommended-list-item-heading">ADVICE</p>
                <p className="report-recommended-list-item-value">
                  {apidata.advice}
                </p>
                <p className="report-recommended-list-item-value">
                  {apidata.repeatVisitComment}
                </p>
                <div className="d-flex justify-content-center width-100">
                  <p className="pdf-iso-text">
                    The Clinical AI Models and APIs used at Apollo Hospitals are
                    certified by ISO 13485 : 2016 vide certificate no. MD 763515
                  </p>
                </div>
                <div className="pdf-logos-container">
                  <img
                    className="pdf-footer-logo"
                    src={Apollo24}
                    alt="apollo24/7.png"
                  />
                  <img
                    className="pdf-footer-logo"
                    src={ApolloImg}
                    alt="askApollo.png"
                  />
                </div>
                <footer className="footer-container">
                  <p className="footer-text mt-auto">
                    This report is accessed by {loginDetails.executive}
                  </p>
                  <div className="date-pageNo-container">
                    <div className="date-time-container">
                      <p className="date-text">Date: {renderCurrentDate()}</p>
                    </div>
                    <p className="page-no-text">Page 3/3</p>
                  </div>
                </footer>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

const AICVDPdfDetails = (props) => {
  const componentRef = useRef();
  const navigate = useNavigate();

  const gotohome = () => {
    navigate("/");
  };

  return (
    <div className="pdf-report-button">
      <PrintPdfDetails ref={componentRef} PdfInfo={props.PdfInfo} />
      <div className="report-buttons-container">
        <button className="button home-button mr-20" onClick={gotohome}>
          Home
        </button>
        <ReactToPrint
          trigger={() => <button className="button pdf-button">Get Pdf</button>}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};

export default AICVDPdfDetails;
