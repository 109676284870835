import React from "react";
import "./index.css";

export default function TableOfContents(props) {
  const { onItemClick } = props;

  return (
    <ul id="TableOfContent" className="Table-of-Content">
      {props.titleNames?.map((item, i) => {
        return (
          <li
            style={{ cursor: "pointer" }}
            key={item + i}
            id={item === props.activeNav ? "activeContent" : ""}
            className="Table-Of-ContentItem"
            onClick={() => onItemClick(props.tableOfContent[i])}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
}
