import React, { useEffect, useRef, useState, useContext } from "react";
import NavBar from "./NavBar";
import ListOfScrollingContent from "./ListOfScrollingContent";
import "./index.css";
import PatientContext from "../../context/PatientContext";
import Cookies from "js-cookie";

export default function LandingPage() {
  const { landingPage } = useContext(PatientContext);
  const [activeNav, setNav] = useState(null);
  const [contentFromAPI, setCcontentFromAPI] = useState({});
  const [indexNames, setIndexNames] = useState([]);
  const [titleNames, setTitleName] = useState([]);
  const [availableApps, setAvailabeApps] = useState([]);

  const changeNavgation = (newNav) => {
    setNav(newNav);
  };
  const contentRef = useRef([]);

  const setRefForScroll = (ref) => {
    contentRef.current = contentRef.current.push(ref);
  };

  useEffect(() => {
    const token = Cookies.get("jwt_token");
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };
    const parseJwtObj = parseJwt(token);
    const accessible_apps = parseJwtObj?.accessible_apps;
    const savedContent = JSON.parse(localStorage.getItem("ContentFromAPI"));
    const landingPage_info =
      landingPage.length !== 0 ? landingPage : savedContent;

    setAvailabeApps(accessible_apps);
    setCcontentFromAPI(landingPage_info);
    setNav(Object.keys(landingPage_info)[0]);
  }, [landingPage, setAvailabeApps]);

  useEffect(() => {
    if (!contentFromAPI || !activeNav) {
      return;
    }
    setIndexNames(contentFromAPI[activeNav].map((c) => c.indexName));
    setTitleName(
      contentFromAPI[activeNav].map((c) => {
        return c.title;
      })
    );
  }, [contentFromAPI, activeNav]);

  return (
    <>
      {activeNav && (
        <div id="MegaContainer">
          <NavBar
            changeNavgation={changeNavgation}
            NavItems={Object.keys(contentFromAPI)}
            activeNav={activeNav}
          />
          <div id="BodyContent">
            <ListOfScrollingContent
              setRefForScroll={setRefForScroll}
              contentList={contentFromAPI[activeNav]}
              tableOfContent={indexNames}
              titleNames={titleNames}
              availableApps={availableApps}
            ></ListOfScrollingContent>
          </div>
        </div>
      )}
    </>
  );
}
