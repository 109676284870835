import React from "react";
import "./index.css";
export default function Index({ showPopUp, togglePopUp, ContentToDisplayInPopUp,buttonText, DontShowButon } ) {
  const stopClickEventPassingToParrent = (event) => {
    event.stopPropagation();
  };
  const handleClick = () => {
    togglePopUp(!showPopUp);
  };
  return showPopUp ? (
    <div className="popUpBackground" onClick={handleClick}>
      <div className="popUPContentBox" onClick={stopClickEventPassingToParrent}>
        {ContentToDisplayInPopUp}
      {!DontShowButon &&<button type="button" className="ClosePopUpButton" onClick={handleClick}>
         {buttonText || "Close"}
        </button>}  
      </div>
    </div>
  ) : null;
}
