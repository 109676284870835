import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PatientContext from "../../../context/PatientContext";
import FieldsContext from "../../../context/FieldsContext";
import OuterFrame from "../OuterFrame";
import Input from "../Input";
import "./index.css";
import PopUpConcent from "../AnyPopUp";
import ConcentForm from "../PaitentConcent";
const PatientDetails = (props) => {
  const navigate = useNavigate();
  const { setPatientDetails } = useContext(PatientContext);
  const { fields } = useContext(FieldsContext);
  const [formErrorStatus, setformErrorStatus] = useState(false);
  const [formErrors, setformErrors] = useState({});
  const [patientDetailsForm, setPatientDetailsForm] = useState([]);
  const [current_app, setApp] = useState(props.app);
  const [showConcent, toggleConcent] = useState(false);
  const [patient_consent, setPatientConsent] = useState();
  const [buttonClicked,setButtonClicked]=useState(false)

  useEffect(() => {
    let formError = {};
    setApp(props.app);
    const savedContent = JSON.parse(localStorage.getItem("fields"));
    const patient_info = fields.Patient_Details
      ? fields.Patient_Details
      : savedContent.Patient_Details;
    setPatientDetailsForm(patient_info);

    patient_info.forEach((field) => {
        formError[field.key] = false;
    });

    setformErrors(formError);

    const patient_consent_info = fields.patient_consent
      ? fields.patient_consent
      : savedContent.patient_consent;
    setPatientConsent(patient_consent_info);
    setApp(props.app);
  }, [fields.Patient_Details, props.app, fields]);

  const handleChange = (key, value) => {
    setPatientDetailsForm((prevDetailsForm) => {
      const updatedForm = prevDetailsForm.map((detail) =>
        detail.key === key ? { ...detail, value } : detail
      );
      validateForm(updatedForm);
      return updatedForm;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonClicked(true)
    if (validateForm(patientDetailsForm)) {
      const formData = patientDetailsForm.reduce((acc, detail) => {
        acc[detail.key] = detail.value;
        return acc;
      }, {});
      setPatientDetails(formData);
      navigate(`/${current_app}/attributes`);
    }
  };
  const validateForm = (updatedForm) => {
    let hasError = false;
    let errors = {};
    updatedForm.forEach((patient) => {
      if (patient.required === "yes" && !patient.value) {
        hasError = true;
        errors[patient.key] = true;
      } else {
        errors[patient.key] = false;
      }
    });
    setformErrors(errors);
    setformErrorStatus(hasError);

    return !hasError;
  };
  return (
    <OuterFrame
      appName={fields.landing_page[0].heading}
      hightlight="1"
      component={
        <>
          <div id="contentContainer">
            <form onSubmit={handleSubmit} className="ActualForm">
              <div className="InputForm">
                {patientDetailsForm.map((patient) => (
                  <React.Fragment key={patient.key}>
                    <Input
                      name={patient.key}
                      placeholder={patient.name}
                      type={patient.type}
                      options={patient.options || []}
                      value={patient.value}
                      onChange={handleChange}
                      formErrors={formErrors}
                      label={patient.name}
                      id={`${patient.key}_${patient.name}`}
                      patient_required={patient.required}
                      buttonClicked={buttonClicked}
                    />
                  </React.Fragment>
                ))}
              </div>
              <div className="showConcentDiv">
                <span
                  className="DocLogoForConcentButton"
                  onClick={() => toggleConcent(true)}
                >
                  📃
                </span>
                <button
                  className="showConcentButton"
                  onClick={() => toggleConcent(true)}
                  type="button"
                >
                  PATIENT INFORMATION SHEET AND INFORMED CONSENT
                </button>
              </div>
              <div className="checkBoxForTC">
                <Input
                  className=""
                  isRequired={true}
                  type="checkbox"
                  id="loginCheckBox1"
                  value="Patient has confirmed that he/she has read and understood
                    the information sheet for the above Risk Scores and have had
                    the opportunity to ask questions"
                />
                <span className="c-checkbox"></span>
                <Input
                  isRequired={true}
                  className=""
                  type="checkbox"
                  id="loginCheckBox2"
                  value="Patient has agreed to take part in the above NCD Risk Scores
                    in Apollo Hospitals."
                />
                <span className="c-checkbox"></span>
              </div>
              {formErrorStatus && buttonClicked && (
                <p className="form-validation-error-text">
                  Please enter all required Fields
                </p>
              )}
              <button className="nextButton" type="submit">
                Next
              </button>
            </form>
          </div>
          <PopUpConcent
            showPopUp={showConcent}
            togglePopUp={() => {
              toggleConcent(!showConcent);
            }}
            ContentToDisplayInPopUp={
              <div className="PopUpForPaitentConcent">
                <ConcentForm data={patient_consent} />
              </div>
            }
          />
        </>
      }
    />
  );
};

export default PatientDetails;
