import React, { useEffect, useState } from "react";
import "./index.css";

const AICVDPatientReport = (props) => {
  const [apidata, setAPI] = useState();

  useEffect(() => {
    const report = props.data.Data[0];
    const prediction = report.Prediction;
    let topRiskContributors = prediction.HeartRisk.TopRiskContributors;

    setAPI({
      optimal: prediction.HeartRisk.Acceptable,
      risk: prediction.HeartRisk.Risk,
      score: prediction.HeartRisk.Score,
      topRiskContributors: topRiskContributors,
      department: prediction.MedicalProtocol.Referral.Department,
      urgency: prediction.MedicalProtocol.Referral.Urgency,
      advice: prediction.MedicalProtocol.Management.GeneralAdvice,
      medication: prediction.MedicalProtocol.Medication,
      diagnosticsAndImagingRecommended:
        prediction.MedicalProtocol.DiagnosticsAndImagingRecommended,
      labInvestigationRecommended:
        prediction.MedicalProtocol.LabInvestigationRecommended,
      repeatVisitComment: prediction.ManagementRepeatVisit,
      repeatVisitDuration:
        prediction.MedicalProtocol.Management.RepeatVisit.Duration,
      repeatVisitUnits: prediction.MedicalProtocol.Management.RepeatVisit.Unit,
      loaderStatus: false,
      aicvd_note: report.aicvd_note,
      treatment_goals: report.treatment_goals,
      AICVD_Cardiac_Risk_Score: report.AICVD_Cardiac_Risk_Score,
      note: report.note,
      riskProfile: report.risk_profile,
    });
  }, [props]);

  let riskCardColor = "";

  if (apidata && apidata.risk === "High Risk")
    riskCardColor = "risk-card-bg-red";
  else if (apidata && apidata.risk === "Moderate Risk")
    riskCardColor = "risk-card-bg-yellow";
  else riskCardColor = "risk-card-bg-green";

  return (
    <div>
      {apidata && (
        <>
          <div className="report-form-container">
            <div className="">
              <div>
                <div className="report-heading-container">
                  <div className="left-line"></div>
                  <h1 className="report-sub-heading">
                    PATIENT CARDIAC RISK SCORE
                  </h1>
                  <div className="right-line"></div>
                </div>
                <div className="risk-box-container">
                  <div className="each-risk-box">
                    <p className="score-box-heading">Risk Status</p>
                    <div className={` ${riskCardColor} score-box`}>
                      {apidata.risk}
                    </div>
                  </div>
                  <div className="each-risk-box">
                    <p className="score-box-heading">Patient Score</p>
                    <div className="score-box font-color">{apidata.score}</div>
                  </div>
                  <div className="each-risk-box">
                    <p className="score-box-heading">Acceptable Score</p>
                    <div className="score-box font-color">
                      {apidata.optimal}
                    </div>
                  </div>
                </div>
                <p className="font-size">{apidata.note}</p>

                <p className="font-size">
                  Note: <span className="">{apidata.aicvd_note}</span>
                </p>
              </div>
              <div>
                <div className="report-heading-container">
                  <div className="left-line"></div>
                  <h1 className="report-sub-heading">
                    TOP MODIFIABLE RISK FACTORS
                  </h1>
                  <div className="right-line"></div>
                </div>

                <ul className="risk-box-container">
                  {apidata.topRiskContributors !== null ? (
                    apidata.topRiskContributors?.map((each) => (
                      <li className="risk-factors font-color" key={each}>
                        {each === "bmi" ? "BMI" : each}
                      </li>
                    ))
                  ) : (
                    <li className="top-risk-card">None</li>
                  )}
                </ul>
                <p className="font-size">
                  {apidata.AICVD_Cardiac_Risk_Score &&
                    apidata.AICVD_Cardiac_Risk_Score["description"]}
                </p>
                <p className="report-recommended-list-item-heading">
                  DISCLAIMER
                </p>
                <ul className="disclaimer-list-container">
                        <li className="disclaimer-list-item">This is not a diagnostic tool and it does not guarantee the accuracy of the result and cannot be independently acted upon.</li>
                        <li className="disclaimer-list-item">This Risk score and Clinical Algorithm is a general guideline for Physicians. Any additional laboratory investigations, Diagnostic Imaging, Treatment or Patient Education related to lifestyle management is under Physician’s or Cardiologist’s discretion.</li>
                        <li className="disclaimer-list-item">To ensure the information in the report is up to date, accurate and correct, Doctor shall be consulted for interpretation of the report.</li>
                        <li className="disclaimer-list-item">Apollo Hospitals and its Staff does not offer any assurance on the information made available or be liable for any loss or damage as the said report is based on the AICVD Cardiac Risk Score without any intervention from their side.</li>
                        <li className="disclaimer-list-item">By usage of AICVD Cardiac Risk Score, it is deemed that beneficiary of this service has agreed to get the same done at his own risk and further agrees with this disclaimer without any limitation or any clauses or sub-clauses.</li>
                    </ul>
              </div>
            </div>
            <div className="">
              <div className="report-heading-container">
                <div className="left-line"></div>
                <h1 className="report-sub-heading">RECOMMENDED PROTOCOL</h1>
                <div className="right-line"></div>
              </div>
              <p className="report-recommended-list-item-heading">
                LAB INVESTIGATION
              </p>
              <ul className="report-recommended-list-item-value">
                {apidata.labInvestigationRecommended &&
                  Object.entries(apidata.labInvestigationRecommended)
                    .filter(([key, value]) => value === "Yes")
                    .map(([key]) => key)
                    .join(", ")}
              </ul>
              <p className="report-recommended-list-item-heading">
                DIAGNOSTICS AND IMAGING
              </p>
              <ul className="report-recommended-list-item-value">
                {apidata.diagnosticsAndImagingRecommended &&
                  Object.entries(apidata.diagnosticsAndImagingRecommended)
                    .filter(([key, value]) => value === "Yes")
                    .map(([key]) => key)
                    .join(", ")}
              </ul>

              <p className="report-recommended-list-item-heading">MEDICATION</p>
              <ul className="report-recommended-list-item-value">
                {apidata.medication &&
                  Object.entries(apidata.medication).map(([key, value]) => {
                    if (value !== "") {
                      return <li key={key}>{key}</li>;
                    } else {
                      return null; // If value is not 'Yes', don't render anything
                    }
                  })}
              </ul>
              <p className="report-recommended-list-item-heading">REFERRAL</p>
              <p className="report-recommended-list-item-value">
                {apidata.department} Referral ({apidata.urgency})
              </p>
              <p className="report-recommended-list-item-heading">
                TREATMENT GOALS
              </p>
              <ul className="report-recommended-list-item-value">
                {apidata.treatment_goals.map((field) => (
                  <li className="report-recommended-list-item-value">
                    <span className="">{field.heading}</span>
                    {field.description}
                  </li>
                ))}
              </ul>
              <p className="report-recommended-list-item-heading">ADVICE</p>
              <p className="report-recommended-list-item-value">
                {apidata.advice}
              </p>
              <p className="report-recommended-list-item-value">
                {apidata.repeatVisitComment}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AICVDPatientReport;
