import React, { useRef, useState } from "react";
import TableOfContent from "../TableOfContent";
import "./index.css";
import { Link } from 'react-router-dom';

export default function ContentComponent(props) {
  const AICVD = useRef(null);
  const Liver = useRef(null);
  const Prediabetes = useRef(null);
  const AICKD = useRef(null);
  const AICOPD = useRef(null);
  const Anesthesia = useRef(null);
  const EWS = useRef(null);
  const CXR = useRef(null);
  const TB = useRef(null);
  const [popup, setpopup] = useState("");

  const handleItemClick = (section) => {
    switch (section) {
      case "AICVD":
        AICVD.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Liver":
        Liver.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Prediabetes":
        Prediabetes.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "AICKD":
        AICKD.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "AICOPD":
        AICOPD.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Anesthesia":
        Anesthesia.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "EWS":
        EWS.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "CXR":
        CXR.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "TB":
        TB.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const noAccessPopup = () => {
    setpopup(true);
  };

  const closetoggle = () => {
    setpopup(false);
  };

  return (
    <div id="BodyContent">
      <TableOfContent
        onItemClick={handleItemClick}
        tableOfContent={props.tableOfContent}
        titleNames={props.titleNames}
      />
      <div className="list-of-scrolling-items">
        {props.contentList.map((content, i) => {
          const ref = {
            AICVD,
            Liver,
            Prediabetes,
            AICKD,
            AICOPD,
            Anesthesia,
            EWS,
            CXR,
            TB,
          }[content.indexName];

          if (i % 2 === 0) {
            return (
              <div
                className="contentBoxs"
                id={content.title + i}
                key={content.indexName}
                ref={ref}
              >
                <div className="upperContent">
                  <div className="textContent">
                    {props.availableApps.includes(
                      content.indexName.toLowerCase()
                    ) ? (
                      <Link
                        to={content.indexName.toLowerCase()}
                        className="link customLink"
                      >
                        <h2 className="textTitle">{content.title}</h2>
                      </Link>
                    ) : (
                      <h2 className="link textTitle" onClick={noAccessPopup}>
                        {content.title}
                      </h2>
                    )}
                    <p
                      style={{ fontFamily: "Roboto, sans-serif" }}
                      className="textPara"
                    >
                      {content.para}
                    </p>
                  </div>
                  <div className="contentImg">
                    <img src={content.img} alt="" srcSet="" />
                  </div>
                </div>
                <div className="lowercontent">
                  {props.availableApps.includes(
                    content.indexName.toLowerCase()
                  ) ? (
                    <Link to={content.indexName.toLowerCase()} className="link">
                      <button className="OpenButton" type="button">
                        Open
                      </button>
                    </Link>
                  ) : (
                    <p className="link" onClick={noAccessPopup}>
                      <button className="OpenButton" type="button">
                        Open
                      </button>
                    </p>
                  )}

                  <div className="line">
                    <div></div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="contentBoxs leftcontentBoxs"
                key={content.indexName}
                ref={ref}
              >
                <div className="upperContent">
                  <div className="contentImg">
                    <img src={content.img} alt="" srcSet="" />
                  </div>
                  <div className="textContent">
                    {props.availableApps.includes(
                      content.indexName.toLowerCase()
                    ) ? (
                      <Link
                        to={content.indexName.toLowerCase()}
                        className="link customLink"
                      >
                        <h2 className="textTitle">{content.title}</h2>
                      </Link>
                    ) : (
                      <h2 className="link textTitle" onClick={noAccessPopup}>
                        {content.title}
                      </h2>
                    )}
                    <p
                      style={{ fontFamily: "Roboto, sans-serif" }}
                      className="textPara"
                    >
                      {content.para}
                    </p>
                  </div>
                </div>
                <div className="lowercontent">
                  <div className="line">
                    <div></div>
                  </div>
                  {props.availableApps.includes(
                    content.indexName.toLowerCase()
                  ) ? (
                    <Link to={content.indexName.toLowerCase()} className="link">
                      <button className="OpenButton" type="button">
                        Open
                      </button>
                    </Link>
                  ) : (
                    <p className="link" onClick={noAccessPopup}>
                      <button className="OpenButton" type="button">
                        Open
                      </button>
                    </p>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
      {popup && (
        <div id="popup-container" className="popup-main-container">
          <div className="warring-popup-container small-mb-25">
            <p className="popup-text-highlight-text">Request Access</p>
            <button
              className="close-button info-button"
              type="button"
              onClick={closetoggle}
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
