import React from "react";
import "./index.css";

const Input = ({
  name,
  placeholder,
  type,
  options = [],
  value,
  units,
  onChange,
  onBlur,
  description,
  readOnly,
  label,
  id,
  isRequired,
  ranges,
  required,
  patient_required,
  formErrors,
  buttonClicked,
}) => {
  const handleFieldError = (event) => {
    const ErrorEl = document.getElementById(`${id}"_error"`);
    const element = document.getElementById(id);
    const patient_element = event.target.value;
    const elementValue = parseInt(event.target.value);
    if (ranges) {
      if (!(elementValue >= ranges[0] && elementValue <= ranges[1])) {
        element.style.border = "1px solid rgb(255, 79, 9)";
        ErrorEl.style.display = "block";
        ErrorEl.style.animation = "error-fade-in 700ms ease";
        event.target.value = "";
      } else {
        element.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        ErrorEl.style.animation = "error-fade-out 700ms ease";
        setTimeout(() => {
          ErrorEl.style.display = "none";
        }, 650);
      }
    } else if (patient_required === "yes") {
      if (patient_element === "") {
        element.style.border = "1px solid rgb(255, 79, 9)";
        ErrorEl.style.display = "block";
        ErrorEl.style.animation = "error-fade-in 700ms ease";
        event.target.value = "";
      } else {
        element.style.border = "1px solid rgba(0, 0, 0, 0.2)";
        ErrorEl.style.animation = "error-fade-out 700ms ease";
        setTimeout(() => {
          ErrorEl.style.display = "none";
        }, 650);
      }
    }
  };
  if (onChange === undefined) {
    const empty = () => {};
    onChange = empty;
  }
  if (type === "radio") {
    return (
      <div className="radio_box_in_input_component inputbox">
        <label htmlFor={id}>
          {required === "yes" && (
            <span>
              <img className="star-icon" src="asterisk.svg" alt="star.svg" />
            </span>
          )}
          {label}
        </label>
        <div className="optionsforRadioBox">
          {options.map((option) => (
            <div className="anRadioOptionInINputcomponent" key={option.value}>
              <input
                type="radio"
                name={name}
                id={id + option.name}
                value={option.value}
                checked={value === option.value}
                onChange={(e) => onChange(name, e.target.value)}
                onBlur={onBlur}
                className="radio_in_input_component"
              />
              <label
                className="lable_in_input_component radio_lable_in_input_component"
                htmlFor={id + option.name}
              >
                {option.name}
              </label>
            </div>
          ))}
        </div>
        {formErrors[name] && buttonClicked && (
          <p className="form-validation-error-text">Please enter {label}</p>
        )}
      </div>
    );
  }

  if (type === "select") {
    return (
      <div className="select_box_in_input_component inputbox">
        <label htmlFor={name}>
          {required === "yes" && (
            <span>
              <img className="star-icon" src="asterisk.svg" alt="star.svg" />
            </span>
          )}
          {label}
          {description && (
            <span className="tooltip">
              i
              <span className="tooltiptext">
                {description.split("\n").map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </span>
            </span>
          )}
        </label>
        <div className="select-box">
          <select
            className="select_in_input_component"
            name={name}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            onBlur={onBlur}
          >
            <option
              className="inputbox select_option_in_input_component"
              value=""
              disabled
            >
              Select
            </option>
            {options.map((option) => (
              <option
                className="inputbox select_option_in_input_component"
                key={option.value}
                value={option.value}
              >
                {option.name}
              </option>
            ))}
          </select>
        </div>
        {formErrors[name] && buttonClicked && (
          <p className="form-validation-error-text font-size">
            Please enter {label}
          </p>
        )}
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <div className="checkbox-input-box checkbox_input_box_in_input_component">
        <input
          name={name}
          required={isRequired}
          id={id}
          type={type}
          value={value}
          onChange={(event) => onChange(event, name, event.target.value)}
          className="inputbox checkbox_box_in_input_component"
        />
        <div className="inputbox checkbox_lable_box_input_in_input_component">
          <label
            className="lable_in_input_component checkbox_lable_in_input_component"
            htmlFor={id}
          >
            {value}
          </label>
        </div>
      </div>
    );
  }

  if (type === "date") {
    return (
      <div className="inputbox">
        <input
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          onBlur={onBlur}
          className="date_in_input_component"
          id={id}
        />
      </div>
    );
  }

  return (
    <div className="inputbox">
      <div>
        <label htmlFor={name}>
          {required === "yes" && (
            <span>
              <img className="star-icon" src="asterisk.svg" alt="star.svg" />
            </span>
          )}
          {label}
          {units && `(${units})`}
          {description && (
            <div className="tooltip">
              i
              <span className="tooltiptext">
                {description.split("\n").map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </span>
            </div>
          )}
        </label>
      </div>
      <input
        name={name}
        id={id}
        placeholder={ranges ? `${ranges[0]} - ${ranges[1]}` : ""}
        type={type}
        value={value}
        onChange={(e) => onChange(name, e.target.value)}
        className="text_input_in_input_component"
        readOnly={readOnly}
        onBlur={(e) => handleFieldError(e)}
      />
      {patient_required && (
        <span id={`${id}"_error"`} className="age-error-text">
          Please enter a valid {label}
        </span>
      )}
      {formErrors[name] && buttonClicked && (
        <span className="form-error-text-valid">
          Please enter a valid {label}
        </span>
      )}
    </div>
  );
};

export default Input;
