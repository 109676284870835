import React from "react";
import "./index.css";
import logo from "./favicon.png";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";


export default function NavBar(props) {
  const navigate = useNavigate("/login");

  const HandleClick = (item) => {
    if (props.activeNav === item) return;
    props.changeNavgation(item);
  };

  
  const logOut = () => {
    Cookies.remove("jwt_token");
    navigate("/login");
  };
  return (
    <div id="Container">
      <div id="logo">
        <img src={logo} alt="" />
      </div>
      <ul id="NavItemsContainer">
        {props.NavItems.map((navItem) => {
          return (
            <li
              style={{ cursor: "pointer" }}
              className="NavItems"
              onClick={() => HandleClick(navItem)}
              key={navItem}
              id={navItem === props.activeNav ? "ActiveNavItem" : "inActive"}
            >
              {navItem}
            </li>
          );
        })}
      </ul>
      <div className="logoff-button">
        <button className="logoff" onClick={logOut} >Logout</button>
      </div>
    </div>
  );
}
