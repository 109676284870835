import React, { useEffect, useState } from "react";
import "./index.css";

const PrediabetesPatientReport = (props) => {
  const [apidata, setAPI] = useState();

  useEffect(() => {
    const report = props.data.data.data;
    setAPI(report);
  }, [props]);

  let riskCardColor = "";

  if (apidata && apidata.risk === "High Risk")
    riskCardColor = "risk-card-bg-red";
  else if (apidata && apidata.risk === "Moderate Risk")
    riskCardColor = "risk-card-bg-yellow";
  else riskCardColor = "risk-card-bg-green";

  return (
    <div>
      {apidata && (
        <>
          <div className="report-form-container">
            <div className="">
              <div>
                <div className="report-heading-container">
                  <div className="left-line"></div>
                  <h1 className="report-sub-heading liver-color  ">
                    PATIENT RISK SCORE
                  </h1>
                  <div className="right-line"></div>
                </div>
                <div className="risk-box-container prediabetes-risk">
                  <div className="each-risk-box">
                    <p className="score-box-heading">Risk Status</p>
                    <div className={` ${riskCardColor} score-box`}>
                      {apidata.risk}
                    </div>
                  </div>
                  <div className="each-risk-box">
                    <p className="score-box-heading">Patient Score</p>
                    <div className="score-box font-color">
                      Probability of High Fibrosis (F2 - F4) -{" "}
                      <span className="score-box-heading padding-left-0">{apidata.risk_score}%</span>
                    </div>
                  </div>
                  <div className="each-risk-box">
                    <p className="score-box-heading">AI Score</p>
                    <div className="score-box font-color">
                      AI Score (1 - 10) [F2 - F4] Score - 
                      <span className="score-box-heading padding-left-0">
                      {(apidata.predicted_score * 10).toFixed(2)}
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="report-heading-container">
                <div className="left-line"></div>
                <h1 className="report-sub-heading liver-color">
                  RECOMMENDED PROTOCOL
                </h1>
                <div className="right-line"></div>
              </div>
              <p className="report-recommended-list-item-heading">
                LAB INVESTIGATION
              </p>
              <p className="report-recommended-list-item-value">
                {apidata.lab_investigation}
              </p>
              <p className="report-recommended-list-item-heading">
                {" "}
                DIAGNOSTICS AND IMAGING{" "}
              </p>
              <p className="report-recommended-list-item-value">
                {apidata.diagnostics_imaging}
              </p>
              <p className="report-recommended-list-item-heading">REFERRAL</p>
              <p className="report-recommended-list-item-value">
                {apidata.referral_to_consultant}
              </p>
              <p className="report-recommended-list-item-heading">
                TREATMENT GOALS
              </p>
              <p className="report-recommended-list-item-value">
                {apidata.treatment_goals}
              </p>
              <p className="report-recommended-list-item-heading">EDUCATE ON</p>
              <p className="report-recommended-list-item-value">
                {apidata.educate_on}
              </p>
              <p className="report-recommended-list-item-heading">
                TEST FOLLOW UP
              </p>
              <p className="report-recommended-list-item-value">
                {apidata.tests_follow_up}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PrediabetesPatientReport;
