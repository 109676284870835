import React, { useEffect, useState } from "react";
import "./index.css";
import ApolloLogo from "../img/Apollo_logo.svg";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";

export default function Index(props) {
  const { component, appName } = props;
  const navigate = useNavigate("/login");
  const [highlight, setHighlight] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("patient-details")) {
      setHighlight(1);
    } else if (location.pathname.includes("attributes")) {
      setHighlight(2);
    } else if (location.pathname.includes("report")) {
      setHighlight(3);
    }
  }, [location]);

  const logOut = () => {
    Cookies.remove("jwt_token");
    navigate("/login");
  };

  const returnToHome = () => {
    navigate("/");
  };

  const circleOneStyle =
    highlight >= 1
      ? highlight === 1
        ? "circle-active"
        : "circle-completed"
      : "";
  const circleTwoStyle =
    highlight >= 2
      ? highlight === 2
        ? "circle-active"
        : "circle-completed"
      : "";
  const circleThreeStyle =
    highlight >= 3
      ? highlight === 3
        ? "circle-active"
        : "circle-completed"
      : "";

  const textOneStyle =
    highlight >= 1 ? (highlight === 1 ? "text-active" : "text-completed") : "";
  const textTwoStyle =
    highlight >= 2 ? (highlight === 2 ? "text-active" : "text-completed") : "";
  const textThreeStyle =
    highlight >= 3 ? (highlight === 3 ? "text-active" : "text-completed") : "";

  return (
    <>
      <nav id="navBar_PDPage">
        <div id="ApolloLogoBox_PDPage">
          <img src={ApolloLogo} alt="" srcset="" />
        </div>
        <h1 id="titleNameOfApplication_PDPage">
          {appName === "aicvd"
            ? "AICVD Report"
            : appName === "prediabetes"
            ? "Prediabetes Report"
            : appName === "liver"
            ? "AILF Report"
            : appName}
        </h1>

        <div className="navigation-buttons">
          <button
            id="LogoOutButton_PDPage"
            type="button"
            onClick={returnToHome}
          >
            Home
          </button>
          <button id="LogoOutButton_PDPage" type="button" onClick={logOut}>
            Log Out
          </button>
        </div>
      </nav>
      <main id="bodyContentContainer">
        <div id="leftColorBlock">
          <div className="steps-container">
            <div className="each-step-container">
              <div className={`circle ${circleOneStyle}`}>
                {highlight > 1 ? (
                  <img
                    className="check-img"
                    src="check-mark-svgrepo-com.svg"
                    alt="checked.png"
                  />
                ) : (
                  1
                )}
              </div>
              <p className={`step-title ${textOneStyle}`}>Details</p>
            </div>
            <div className="each-step-container">
              <div className={`circle ${circleTwoStyle}`}>
                {highlight > 2 ? (
                  <img
                    className="check-img"
                    src="check-mark-svgrepo-com.svg"
                    alt="checked.png"
                  />
                ) : (
                  2
                )}
              </div>
              <p className={`step-title ${textTwoStyle}`}>Attributes</p>
            </div>
            <div className="each-step-container">
              <div className={`circle ${circleThreeStyle}`}>
                {highlight === 3 ? (
                  <img
                    className="check-img"
                    src="check-mark-svgrepo-com.svg"
                    alt="checked.png"
                  />
                ) : (
                  3
                )}
              </div>
              <p className={`step-title ${textThreeStyle}`}>Report</p>
            </div>
          </div>

          <div>
            {appName === "AICVD Risk Score" || appName === "aicvd" ? (
              <div className="nav-document-container">
                <a
                  className="doc-link-text"
                  target="_blank"
                  href="001-IFU - FAQ AICVD V1.1.pdf"
                >
                  <img
                    className="doc-icon"
                    src="icons8-pdf-48.png"
                    alt="doc.icon"
                  />
                  User manual
                </a>
                <a
                  className="doc-link-text"
                  target="_blank"
                  href="New Incident Report Form.docx"
                >
                  <img
                    className="doc-icon"
                    src="icons8-microsoft-word-2019-48.png"
                    alt="doc.icon"
                  />
                  Event Reporting Form
                </a>
              </div>
            ) : appName === "Apollo Prediabetes Scanner" ||
              appName === "prediabetes" ? (
              <div className="nav-document-container">
                <a className="doc-link-text" target="_blank" href="001-IFU - FAQ Prediabetes v1.1.pdf">
                  <img
                    className="doc-icon"
                    src="./icons8-pdf-48.png"
                    alt="doc.icon"
                  />
                  User manual
                </a>
                <a
                  className="doc-link-text"
                  target="_blank"
                  href="New Incident Report Form.docx"
                >
                  <img
                    className="doc-icon"
                    src="./icons8-microsoft-word-2019-48.png"
                    alt="doc.icon"
                  />
                  Event Reporting Form
                </a>
              </div>
            ) : appName === "Apollo AILF" || appName === "liver" ? (
              <div className="nav-document-container">
                <a className="doc-link-text" target="_blank" href="001-IFU - FAQ AILF 1.1.pdf">
                  <img
                    className="doc-icon"
                    src="icons8-pdf-48.png"
                    alt="doc.icon"
                  />
                  User manual
                </a>
                <a
                  className="doc-link-text"
                  target="_blank"
                  href="New Incident Report Form.docx"
                >
                  <img
                    className="doc-icon"
                    src="icons8-microsoft-word-2019-48.png"
                    alt="doc.icon"
                  />
                  Event Reporting Form
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div id="rightContentBlock">{component}</div>
      </main>
    </>
  );
}
