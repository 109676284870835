import React, { createContext, useState } from "react";

const PatientContext = createContext();

export const PatientProvider = ({ children }) => {
  const [patientDetails, setPatientDetails] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [landingPage, setLandingPage] = useState([]);
  const [attribute_values, setAttributeValues] = useState({});
  const [contextPatientReport, setContextPatientReport] = useState({});

  return (
    <PatientContext.Provider
      value={{
        patientDetails,
        setPatientDetails,
        attributes,
        setAttributes,
        landingPage,
        setLandingPage,
        attribute_values,
        setAttributeValues,
        contextPatientReport,
        setContextPatientReport,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export default PatientContext;
