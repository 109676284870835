import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppLandingPage from "../../common/AppLandingPage";
import PatientDetails from "../../common/PatientDetails";
import Attributes from "../../common/Attributes";
import Report from "../../common/Report";
import { FieldsProvider } from "../../../context/FieldsContext";
import AICVDPdfDetails from "../../../components/pdfReports/AICVDPdfReport";

const AICVDRoutes = (props) => {
  // const AICVD_LANDING_URL = process.env.REACT_APP_AICVD_LANDING_URL;
  // const AICVD_REPORT_URL = process.env.REACT_APP_AICVD_REPORT_URL;

  const AICVD_LANDING_URL =
    "https://clinical-server-apis.azurewebsites.net/fields/aicvd";
  const AICVD_REPORT_URL =
    "https://clinical-server-apis.azurewebsites.net/aicvd";

  const [formDetails, setFormDetails] = useState({});
  const [PdfInfo, setPDFDetails] = useState({});

  const app = "aicvd";
  const attribute_values = {
    height: "",
    weight: "",
    bmi: "",
    heart_rate: "",
    systolic_bp: "",
    diastolic_bp: "",
    respiration_rate: "",
    pulse_rhythm: "",
    heart_symptoms: "",
    pyschological_stress: "",
    diet: "",
    smoking: "",
    tobacco: "",
    alcohol: "",
    diabetes: "",
    physical_activity: "",
    hypertension: "",
    dyslipidaemia: "",
    family_heart_disease: "",
    heart_disease: "",
  };

  const form_details = (props) => {
    const details = {
      patient_id: props.patient_id,
      name: props.name,
      age: props.age,
      gender: props.gender,
      location: props.location,
      email: props.email,
      phone: props.phone,
      height: props.height,
      weight: props.weight,
      bmi: props.BMI,
      heart_rate: props.heart_rate,
      systolic_bp: props.systolic_bp,
      diastolic_bp: props.diastolic_bp,
      respiration_rate: props.respiration_rate,
      pulse_rhythm: props.pulse_rhythm,
      heart_symptoms: props.heart_symptoms,
      pyschological_stress: props.pyschological_stress,
      diet: props.diet,
      smoking: props.smoking,
      tobacco: props.tobacco,
      alcohol: props.alcohol,
      physical_activity: props.physical_activity,
      hypertension: props.hypertension,
      dyslipidaemia: props.dyslipidaemia,
      heart_disease: props.heart_disease,
      family_heart_disease: props.family_heart_disease,
      diabetes: props.diabetes,
    };

    const pdf_details = {
      Height: props.height,
      Weight: props.weight,
      Bmi: props.BMI,
      "Heart Rate": props.heart_rate,
      "Systolic Bp": props.systolic_bp,
      "Diastolic Bp": props.diastolic_bp,
      "Respiration Rate": props.respiration_rate,
      "Pulse Rhythm": props.pulse_rhythm,
      "Heart Symptoms": props.heart_symptoms,
      "Pyschological Stress": props.pyschological_stress,
      Diet: props.diet,
      Smoking: props.smoking,
      Tobacco: props.tobacco,
      Alcohol: props.alcohol,
      "Physical Activity": props.physical_activity,
      Hypertension: props.hypertension,
      dyslipidaemia: props.dyslipidaemia,
      "Heart Disease": props.heart_disease,
      "Family Heart Disease": props.family_heart_disease,
      Diabetes: props.diabetes,
    };
    console.log(JSON.stringify(formDetails),"formDetails")
    setFormDetails(details);
    setPDFDetails(pdf_details);
  };

  return (
    <FieldsProvider>
      <Routes>
        <Route
          path="/"
          element={<AppLandingPage LANDING_URL={AICVD_LANDING_URL} app={app} />}
        />
        <Route path="patient-details" element={<PatientDetails app={app} />} />
        <Route
          path="attributes"
          element={
            <Attributes
              app={app}
              attribute_values={attribute_values}
              form_details={form_details}
            />
          }
        />
        <Route
          path="report"
          element={
            <Report
              app={app}
              formDetails={formDetails}
              REPORT_URL={AICVD_REPORT_URL}
            />
          }
        />
        <Route
          app={app}
          path="pdfreport"
          element={
            <AICVDPdfDetails formDetails={formDetails} PdfInfo={PdfInfo} />
          }
        />
      </Routes>
    </FieldsProvider>
  );
};

export default AICVDRoutes;
