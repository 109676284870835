import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppLandingPage from "../../common/AppLandingPage";
import PatientDetails from "../../common/PatientDetails";
import Attributes from "../../common/Attributes";
import Report from "../../common/Report";
import { FieldsProvider } from "../../../context/FieldsContext";
import LiverPdfDetails from "../../pdfReports/LiverReport";

const LiverRoutes = (props) => {
  // const LIVER_LANDING_URL = process.env.REACT_APP_LIVER_LANDING_URL;
  // const LIVER_REPORT_URL = process.env.REACT_APP_LIVER_REPORT_URL;

  const LIVER_LANDING_URL =
    "https://clinical-server-apis.azurewebsites.net/fields/liver";
  const LIVER_REPORT_URL =
    "https://clinical-server-apis.azurewebsites.net/liver";
    


  const app = "liver";
  const [formDetails, setFormDetails] = useState({});
  const [PdfInfo, setPDFDetails] = useState({});

  const attribute_values = {
    patient_id: "",
    name: "",
    age: "",
    gender: "",
    location: "",
    email: "",
    phone: "",
    height: "",
    weight: "",
    bmi: "",
    albumin: "",
    alkphos_alkaline_phosphotase: "",
    cholesterol: "",
    ldl_cholesterol: "",
    platelet_count: "",
    sgot: "",
    sgpt: "",
    total_bilirubin: "",
    total_protiens: "",
    alcohol: "",
    alcohol_sub: "",
    anti_hcv: "",
    anti_hcv_no_years: "",
    diabetes: "",
    diabetes_test_date: "",
    dyslipidemia: "",
    dyslipidemia_list: [],
    dyslipidemia_second_list: "",
    dyslipidemia_third_list: "",
    fatty_liver: "",
    fbs_test_date: "",
    fbs_test_value: "",
    hav_igm: "",
    hav_igm_no_years: "",
    hba1c_test_date: "",
    hba1c_test_value: "",
    hbsag: "",
    hbsag_no_years: "",
    hepatitis: [],
    hev_igm: "",
    hev_igm_no_years: "",
    homa_ir_test_value: "",
    hypertension: "",
    hypertension_list: [],
    hypertension_second_list: [],
    liver_disease: "",
    homa_ir_test_date: "",
  };
  const form_details = (props) => {
    const details = {
      patient_id: props.patient_id,
      name: props.name,
      age: props.age,
      gender: props.gender,
      location: props.location,
      email: props.email,
      phone: props.phone,
      height: parseInt(props.height),
      weight: parseInt(props.weight),
      bmi: props.BMI,
      albumin: props.albumin,
      alkphos_alkaline_phosphotase: props.alkphos_alkaline_phosphotase,
      cholesterol: props.cholesterol,
      ldl_cholesterol: props.ldl_cholesterol,
      platelet_count: props.platelet_count,
      sgot: props.sgot,
      sgpt: props.sgpt,
      total_bilirubin: props.total_bilirubin,
      total_protiens: props.total_protiens,
      fatty_liver: props.fatty_liver,
      diabetes: props.diabetes !== "No" ? "yes" : "no",
      diabetes_test_date: props.diabetes_test_date,
      diabetes_test_value: props.diabetes_test_value,
      fbs_test_date: props.fbs_test_date,
      fbs_test_value: props.fbs_test_value,
      hba1c_test_date: props.hba1c_test_date,
      hba1c_test_value: props.hba1c_test_value,
      homa_ir_test_date: props.homa_ir_test_date,
      homa_ir_test_value: props.homa_ir_test_value,
      liver_disease:
        props.liver_disease !== "" ? parseFloat(props.liver_disease) : 0,
      hypertension: props.hypertension !== "No" ? "yes" : "no",
      hypertension_list: props.hypertension_list,
      hypertension_second_list: props.hypertension_second_list,
      hepatitis: props.hepatitis,
      hav_igm: props.hepatitis?.includes("HAV IgM") ? "HAV IgM" : "",
      hav_igm_no_years: props.hav_igm_no_years,
      anti_hcv: props.hepatitis?.includes("anti-HCV") ? "anti-HCV" : "",
      anti_hcv_no_years: props.anti_hcv_no_years,
      hev_igm: props.hepatitis?.includes("HEV IgM") ? "HEV IgM" : "",
      hev_igm_no_years: props.hev_igm_no_years,
      hbsag: props.hepatitis?.includes("HBsAg") ? "HBsAg" : "",
      hbsag_no_years: props.hbsag_no_years,
      alcohol: props.alcohol !== "No" ? "yes" : "no",
      alcohol_sub: props.alcohol_sub,
      diabetes_condition: props.diabetes,
      dyslipidemia: props.dyslipidemia,
      dyslipidemia_list: props.dyslipidemia_list,
      dyslipidemia_second_list: props.dyslipidemia_second_list,
      dyslipidemia_third_list: props.dyslipidemia_third_list,
      liver_disease_history: props.liver_disease_history,
    };

    console.log(JSON.stringify(details),"details")

    const pdf_details = {
      Height: props.height,
      Weight: props.weight,
      Bmi: props.BMI,
      albumin: props.albumin,
      "Alkphos Alkaline Phosphotase": props.alkphos_alkaline_phosphotase,
      Cholesterol: props.cholesterol,
      "Ldl Cholesterol": props.ldl_cholesterol,
      "Platelet Count": props.platelet_count,
      Sgot: props.sgot,
      Sgpt: props.sgpt,
      "Total Bilirubin": props.total_bilirubin,
      "Total Protiens": props.total_protiens,
      "Fatty Liver": props.fatty_liver,
      "Liver Disease":
        props.liver_disease !== "" ? parseFloat(props.liver_disease) : 0,
      Hypertension: props.hypertension,
      dyslipidaemia: props.dyslipidaemia,
      Diabetes: props.diabetes,
      "Hav igm": props.hepatitis?.includes("HAV IgM") ? "HAV IgM" : "",
      "Anti Hcv": props.hepatitis?.includes("anti-HCV") ? "anti-HCV" : "",
      "Hev Igm": props.hepatitis?.includes("HEV IgM") ? "HEV IgM" : "",
      Hbsag: props.hepatitis?.includes("HBsAg") ? "HBsAg" : "",
      Alcohol: props.alcohol,
      "Liver Disease History": props.liver_disease_history,
    };
    setFormDetails(details);
    setPDFDetails(pdf_details);
  };
  return (
    <FieldsProvider>
      <Routes>
        <Route
          path="/"
          element={<AppLandingPage LANDING_URL={LIVER_LANDING_URL} app={app} />}
        />
        <Route path="patient-details" element={<PatientDetails app={app} />} />
        <Route
          path="attributes"
          element={
            <Attributes
              app={app}
              attribute_values={attribute_values}
              form_details={form_details}
            />
          }
        />
        <Route
          path="report"
          element={
            <Report
              app={app}
              formDetails={formDetails}
              REPORT_URL={LIVER_REPORT_URL}
            />
          }
        />
        <Route
          path="pdfreport"
          element={<LiverPdfDetails app={app} PdfInfo={PdfInfo} />}
        />
      </Routes>
    </FieldsProvider>
  );
};

export default LiverRoutes;
